import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/apps/org/src/app/[locale]/(public)/donate/[channel]/[receiver-type]/[slug]/_dependencies/components/Layout/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/apps/org/src/app/[locale]/(public)/donate/[channel]/[receiver-type]/[slug]/_dependencies/components/Layout/Header.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/apps/org/src/app/[locale]/(public)/donate/[channel]/[receiver-type]/[slug]/layout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/apps/org/src/shared/analytics/CookieBanner/ClientCookieBanner.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/apps/org/src/shared/helpers/utils/scrollUtils.ts");
;
import(/* webpackMode: "eager" */ "/app/frontend/packages/assets/images/logos/gemeinsam_helfen_de.svg");
;
import(/* webpackMode: "eager" */ "/app/frontend/packages/assets/images/logos/nussbaum.svg");
;
import(/* webpackMode: "eager" */ "/app/frontend/packages/assets/images/logos/wirwunder_logo.svg");
;
import(/* webpackMode: "eager" */ "/app/frontend/packages/assets/images/logos/wirwunder_sparkasse.svg");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/app/node_modules/.pnpm/@apollo+client-react-streaming@0.11.8_@apollo+client@3.12.8_@types+react@18.3.18_graphql-ws@5_lshigmix6gderdcxsleprwszwi/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.26.3_next@14.2.25_@babel+core@7.26.7_@opentelemetry+api@1.9.0_@playwright+test@1._7u7fgqmh4e45aevtq3sannytn4/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.25_@babel+core@7.26.7_@opentelemetry+api@1.9.0_@playwright+test@1.50.0_react-dom@18_jadi4dkln4bfu2m3ktpy6vizpy/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.25_@babel+core@7.26.7_@opentelemetry+api@1.9.0_@playwright+test@1.50.0_react-dom@18_jadi4dkln4bfu2m3ktpy6vizpy/node_modules/next/dist/client/link.js");
